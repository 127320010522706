<template>
  <div>
    <van-nav-bar
      title="我的消息"
      left-text="返回"
      left-arrow
      @click-left="Bank"
    />
    <van-search
      v-model="value"
      show-action
      placeholder="请输入搜索关键词"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div
      class="FarmlandList"
      v-for="(item, index) in list"
      :key="index"
      @click="getListId(item.id)"
    >
      <div class="FarmlandList-box">
        <div class="FarmlandList-box-left">
          <div class="FarmlandList-box-left-img">
            <img
              :src="
                item.infoAttach == null
                  ? srcList.full_path
                  : item.infoAttach.full_path
              "
              alt=""
              style="width:70px;height:60px"
            />
          </div>
          <div class="FarmlandList-box-left-word">
            <div class="FarmlandList-box-left-title">{{ item.content }}</div>
            <div class="location">
              专家回复:{{ item.reply || "暂无专家回复" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar, Search } from "vant";
import { getInfoList, getInfo } from "@/service/info";
export default {
  name: "message",
  data() {
    return {
      list: [],
      value: "",
      srcList: {
        full_path: require("../../assets/image/crop/crop_mihoutao.png")
      }
    };
  },
  components: {
    VanNavBar: NavBar,
    VanSearch: Search
  },
  mounted() {
    this.Info();
  },
  methods: {
    Bank() {
      this.$router.go(-1);
    },
    onSearch() {
      getInfo(this.value).then((res) => {
        this.list = res.data;
      })
    },
    Info() {
      getInfoList().then(res => {
        const data = res.data;
        this.list = data;
      });
    },
    getListId(id) {
      this.$router.push({
        path: `/messagedetail/${id}`
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.FarmlandList {
  margin-bottom: 10px;
  .FarmlandList-box {
    display: flex;
    .FarmlandList-box-left {
      width: 80%;
      display: flex;
      .FarmlandList-box-left-img {
        width: 70px;
        height: 60px;
        flex-shrink: 0;
      }
      .FarmlandList-box-left-word {
        margin-top: 5px;
        margin-left: 5px;
        .FarmlandList-box-left-title {
          display: block;
          width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .location {
          margin-top: 16px;
          display: block;
          width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .FarmlandList-box-right {
      .FarmlandList-box-right-word {
        display: flex;
        .farmland-type {
          position: absolute;
          left: 188px;
          top: 4px;
        }
        .farmland-cycle {
          margin-top: 5px;
          position: absolute;
          right: 50px;
        }
      }
      .FarmlandList-box-right-area {
        position: absolute;
        right: 50px;
        bottom: 2px;
      }
    }
    .checkboxe {
      position: relative;
      left: 40px;
      top: 10px;
    }
  }
}
</style>
