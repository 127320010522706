import server from "./axios.config.js";
export const getInfoList = function() {
  return server({
    method: "get",
    url: "http://110.87.103.59:18086/farmfriend/info/getList"
  });
};
export const postInfoDetail = function(id) {
  return server({
    method: "get",
    url: `http://110.87.103.59:18086/farmfriend/info/getById?id=${id}`
  });
};

export const postInfoDelete = function(id) {
  return server({
    method: "post",
    url: "http://110.87.103.59:18086/farmfriend/info/delete",
    data: { id }
  });
};

export const getInfo = function(content) {
  return server({
    method: "get",
    url: `http://110.87.103.59:18086/farmfriend/info/getList?content=${content}`
  });
};
